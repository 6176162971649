/**
 * Created by accidie on 17/1/4.
 */
export default {
    getComponent(nextState, cb) {
        require.ensure([], require =>
            cb(null, require('./Home').default)
        )
    }
}
