/**
 * Created by atyun on 16/11/12.
 */
import {httpHelper} from '../utils/http-helper'
import {notification} from 'antd';

class AddressHelper {
    constructor() {
    }

    /*
     * 获取国家列表
     *
     * callback     回调函数
     * */
    getCountry(callback,value,string) {
        const type = 'get'
        const url = '/api/address_units.json'
        const params = {
            user_session_key: Cookies.get('user_session_key')
        }
        httpHelper.REQUEST(url, params, type).then((response) => {
            if (typeof callback === 'function') {
                if(response.data.status.code === '20000'){
                    let address_units = []
                    const address = response.data.data.address_units || []
                    if(value === 'all'){
                        address_units = address
                    }else{
                        for (const item of address) {
                            const obj = {
                                text: item.name,
                                value: value ? item[value] : string ? item.id.toString(): item.id
                            }
                            address_units.push(obj)
                        }
                    }
                    callback(address_units)
                }
                else{
                    notification.error({
                        description: response.data.status.message
                    })
                }
            } else {
                console.log('参数错误')
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /*
     * 获取省份，城市或区县
     * id         国家，省份或者城市的id
     * callback   回调函数
     * */
    getProviceOrCityOrDistrict(id, callback) {
        const url = '/api/address_units/subordinate.json'
        const type = 'get'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            select_id: id
        }
        httpHelper.REQUEST(url, params, type).then((response) => {
            if (typeof callback === 'function') {
                callback(response)
            } else {
                console.log('参数错误')
            }
        }).catch((e) => {
            console.log(e)
        })
    }
}

let addressHelper = new AddressHelper()
export {addressHelper}
