/**
 * Created by atyun on 17/1/11.
 */
export default {
    breadcrumbName: '',
    getComponent(nextState, cb) {
        require.ensure([], require =>
            cb(null, require('./Home').default)
        )
    }
}
