/**
 * Created by atyun on 16/11/12.
 */


class InterfaceHelper{
    
    //自动化测试创建文件
    getTestFile(text){
        var file = new File(['自动化测试上传文件'] , text+'.jpg' , {type: 'image/jpg'})
        const files = []
        const test_file = {
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            fileUrl: window.URL.createObjectURL(file),
            url: window.URL.createObjectURL(file),
            file_content_type: text === '成绩单' ? 'six_term' : '',
            id:''
        }
        if(text === '上传CoE'){
            test_file['school_course_info_id']= ''
            test_file['school_id'] = ''
            files.push(test_file)
        }
        files.push(test_file)

        return files
    }

}

let interfaceHelper = new InterfaceHelper()
export {interfaceHelper}
