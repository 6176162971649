/**
 * Created by accidie on 16/10/17.
 */


import { Link } from 'react-router'
import styles from './home_change.scss'

class Home extends React.Component {
    constructor() {
        super()
    }

    componentDidCatch(error, errorInfo) {

        // if we have Bugsnag in this environment, we can notify our error tracker
        if (window.Bugsnag) {
            window.Bugsnag.notify(error);
        }
    }

    componentDidMount(){

        // 若是已有udesk,将其隐藏
        let udesk_container = document.getElementById('udesk_container')
        if(window.customerInserted && udesk_container){
            udesk_container.style.display = 'none'
        }

        let is_reme = Cookies.get('is_reme')
        if(is_reme === '1'){
            this.context.router.push('/login')
        }
    }

    loginAction = () => {
        if (Cookies.get('user_id') && Cookies.get('current_role')) {
            this.context.router.push(`/${Cookies.get('current_role')}`)
        } else {
            // let connectedSocket = localStorage.getItem('connectedSocket')
            localStorage.clear()
            // if(connectedSocket === 'true') {
            //     localStorage.setItem('connectedSocket', 'true')
            // }
            for (const o of ['user_session_key', 'current_role', 'user_id', 'user']) {
                if (Cookies.get(o)) {
                    Cookies.remove(o)
                }
            }
            this.context.router.push('/login')
        }
    }

    render() {
        return (
            <div styleName='home--box'>
                <div styleName="detail">
                    <h1>AA留学线上申请平台</h1>
                    <h3>简单、便捷、保障、高效、透明!</h3>
                </div>

                <div styleName="btn--group">
                    <Link onClick={this.loginAction}>登录</Link>
                </div>
            </div>
        )
    }
}

Home.contextTypes = {
    router: PropTypes.object
}

export default Home
