/**
 * Created by atyun on 16/11/12.
 */
import { notification } from 'antd';

class RequestHelper {
    // 提供使用频率高的接口返回值
    // constructor() {
    // this.user_session_key = Cookies.get('user_session_key')
    // }

    /*
     * 获取操作历史
     *
     * others       其他参数
     * callback     回调函数
     * */
    getHistory(others, cb) {
        const type = 'get';
        const url = '/api/auto_applications/actives.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...others
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            const result = res.data.data
            if (res.data.status.code === '20000') {
                cb(result)
            } else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /*
     * 获取课程列表 新建、编辑课程
     *
     * others       其他参数
     * callback     回调函数
     * type       请求方式
     * id       课程id
    * */
    getCourses(others, cb, type = 'get', id) {
        const url = id ? `/api/courses/${id}.json` : '/api/courses.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...others
        };
        if (type === 'get') { params['size'] = 10 }

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            const result = res.data.data
            const code = res.data.status.code
            if (code === '20000') {
                cb(type === 'get' ? result : code)
            } else {
                if (type !== 'get') {
                    cb(code)
                }
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /*
     * 获取自动化网申信息
     *
     * others       其他参数
     * callback     回调函数
    * */
    getSydneyInfo(others, cb) {
        const type = 'get';
        const url = '/api/sydney_applications/get_params.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...others
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            const result = res.data.data
            if (res.data.status.code === '20000') {
                cb(result)
            } else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /*
     * 获取sydney操作历史
     *
     * others       其他参数
     * callback     回调函数
     * */
    getSydneyHistory(others, cb) {
        const type = 'get';
        const url = '/api/sydney_applications/actives.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...others
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            const result = res.data.data
            if (res.data.status.code === '20000') {
                cb(result)
            } else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     *  获取unsw院校信息
     */
    getUnswInfo(others, cb) {
        const type = 'get'
        const url = '/api/aunsw_applications/get_params.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...others
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            const result = res.data.data
            if (res.data.status.code === '20000') {
                cb(result)
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     *  获取unsw操作历史
     */
    getUnswHistory(other, cb) {
        const type = 'get';
        const url = '/api/aunsw_applications/actives.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...other
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            const result = res.data.data
            if (res.data.status.code === '20000') {
                cb(result)
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取、更新代理画像信息
     */
    AgentPortrait(agent_id, type, cb, other) {
        const url = `/api/agent_portraits/${agent_id}.json`;
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            agent_id,
            ...other
        };
        if (type === 'patch') { delete params.agent_id }

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                const agent_portrait = res.data.data.agent_portrait;
                cb(type === 'patch' ? res : agent_portrait);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取代理基本信息
     */
    getAgentBasicInfo(agent_id, cb) {
        const type = 'get';
        const url = '/api/agents/basic_info.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            agent_id,
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                const agent = res.data.data.agent;
                cb(agent);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取字典状态详情
     * @param id
     * list_data 传入需要返回的数据类型
     * is_inactive 是否不要过滤掉status为active的数据
     */
    getDictionaryStatus(id, cb, list_data = ['name', 'id'], is_inactive) {
        const type = 'get';
        const url = '/api/manager/data_dictionaries.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            'search[order]': 'data_dictionaries.seq ASC NULLS LAST',
            'search[data_dictionaries.parent_id]': id,
        }
        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            let data_dictionaries = res.data.data.data_dictionaries;
            if (res.data.status.code === '20000') {
                let data = [];
                if (data_dictionaries) {
                    if (!is_inactive) {
                        data_dictionaries = data_dictionaries.filter(obj => obj.status === 'active')
                    }
                    data_dictionaries.map((obj) => {
                        data.push({
                            text: obj[list_data[0]],
                            value: obj[list_data[1]]
                        })
                    });
                }
                cb(data);
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取数据字典中的值
     * @param list_data 传入需要返回的数据类型
     */
    //isFilter true:筛选 false:不筛选
    getDataFromDictionaries(class_name = 'apply_result', attribute_name = 'reject_reason', extends_params, list_data, isFilter = true) {
        const type = 'get';
        const url = '/api/manager/data_dictionaries.json';
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            'search[data_dictionary_categories.class_name]': class_name,
            'search[data_dictionary_categories.attribute_name]': attribute_name,
            'search[order]': 'data_dictionaries.seq ASC NULLS LAST',
            ...extends_params
        }

        return Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            let data_dictionaries = res.data.data.data_dictionaries || [];
            if (res.data.status.code === '20000') {
                data_dictionaries = isFilter ? data_dictionaries.filter(obj => obj.status === 'active') : data_dictionaries
                let data = data_dictionaries
                if (list_data && data_dictionaries) {
                    data = []
                    data_dictionaries.map((obj) => {
                        data.push({
                            text: obj[list_data[0]],
                            value: obj[list_data[1]]
                        })
                    });
                }
                return data
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取学校列表
     */
    getSchools(country_id, cb) {
        const type = 'get'
        const url = '/api/schools.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            // 添加这个标志是为了减少返回的数据量
            page_source: 'apply_form_index'
        }
        if (country_id) { params['search[schools.country_id]'] = country_id }

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                const arr = [];
                const schools = res.data.data.schools
                if (schools) {
                    for (const item of schools) {
                        const obj = {
                            text: item.name_en + " : " + item.full_name_en,
                            value: item.id
                        };
                        arr.push(obj)
                    }
                }
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获院校简称列表
     */
    getSchoolEnList(cb, fuzzy_name, list_data = ['name_en', 'id']) {
        const type = 'get'
        const url = '/api/schools/school_list.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            fuzzy_name
        }
        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const schools = res.data.data.schools
                if (schools) {
                    if (list_data === 'all') {
                        arr = schools
                    }
                    else {
                        for (const item of schools) {
                            const obj = {
                                text: item[list_data[0]],
                                value: item[list_data[1]]
                            };
                            arr.push(obj)
                        }
                    }
                }
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取院校列表
     */
    getSchoolFuzzyNameList(extends_params, cb, list_data, key = 'text') {
        const type = 'get';
        const url = '/api/schools/search_school_by_fuzzy_name.json';
        let params = {
            user_session_key: Cookies.get('user_session_key'),
            'search[order]': 'name_en asc',
            ...extends_params
        };

        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const schools = res.data.data.schools
                if (list_data === 'all') {
                    arr = schools
                } else {
                    for (const item of res.data.data.schools) {
                        const obj = {
                            [key]: list_data[0].length === 1 ? item[list_data[0][0]] : item[list_data[0][0]] ? item[list_data[0][0]] + " : " + item[list_data[0][1]] : item[list_data[0][1]],
                            value: item[list_data[1]].toString(),
                            value_id: item.id

                        };
                        arr.push(obj)
                    }
                }

                cb(arr);
            } else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }


    /**
     * 获取代理机构
     * name 有值就是取name 为空在取id
     */
    getAgency(cb, name, isId) {
        // fix 数据量太大组件卡顿的问题 pm 24211
        // const type = 'get'
        // const url = '/api/report_forms/agent_list.json'
        // const params = {
        //     user_session_key: Cookies.get('user_session_key'),
        //     'search[like_agent_name]': ''
        // }

        // Utils.httpHelper.REQUEST(url, params, type).then((res) => {
        //     if (res.data.status.code === '20000') {
        //         const arr = [];
        //         const agency = res.data.data.result
        //         if(agency){
        //             for (const item of agency) {
        //                 const obj = {
        //                     text: item.agent_name,
        //                     value: name ? item.agent_name || item.agent_name_id : item.agent_name_id
        //                 };
        //                 arr.push(obj)
        //             }
        //             arr.unshift({text:'全部',value: ' '})
        //         }
        //         cb(_.uniqBy(arr, 'value'));
        //     }
        //     else{
        //         notification.error({
        //             description: res.data.status.message
        //         })
        //     }
        // }).catch((e) => {
        //     console.log(e)
        // })

        const extends_params = {
            'search[like_agents.abbreviant]': name,
            case: true,
            is_need_users: true,
        }
        const type = 'get'
        const url = '/api/agents/employee_users.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...extends_params
        }
        Utils.httpHelper.REQUEST(url, Utils.dataHelper.remove_empty_from_obj(params), type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const agents = res.data.data.agents
                for (const item of agents) {
                    const obj = {
                        text: item.abbreviant,
                        value: isId ? item.id : item.abbreviant
                    };
                    arr.push(obj)
                }
                arr.unshift({ text: '全部', value: ' ' })
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获课程类别
     * value 取value值的参数  all不返回解析后的数组
     * all 是否需要返回全部
     */
    getCourseList(cb, value, all) {
        const type = 'get'
        const url = '/api/course_categories/list_name_ens.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
        }
        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const course_type = res.data.data.course_category_name_ens
                if (course_type) {
                    if (value === 'all') {
                        arr = course_type
                    }
                    else {
                        for (const item of course_type) {
                            const obj = {
                                text: item.name_en,
                                value: value ? item[value] : item.name_en
                            };
                            arr.push(obj)
                        }
                        if (!all) {
                            arr.unshift({ text: '全部', value: ' ' })
                        }
                    }
                }
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取代理简称
     * extends_params 参数
     * value 是否返回解析好的对象
     */
    getAgentAbbreviantList(extends_params, cb, value) {
        const type = 'get'
        const url = '/api/agents/employee_users.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...extends_params
        }
        Utils.httpHelper.REQUEST(url, Utils.dataHelper.remove_empty_from_obj(params), type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const agents = res.data.data.agents
                if (agents) {
                    if (value === 'all') {
                        arr = agents
                    }
                    else {
                        for (const item of agents) {
                            const obj = {
                                text: item.abbreviant,
                                value: item.id
                            };
                            arr.push(obj)
                        }
                    }
                }
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取学校英文简称
     * extends_params 参数
     * value 是否返回解析好的对象
     */
    getSchoolNameList(extends_params, cb, value) {
        const type = 'get'
        const url = '/api/schools/get_schools_name_ens.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            ...extends_params
        }
        Utils.httpHelper.REQUEST(url, Utils.dataHelper.remove_empty_from_obj(params), type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const schools = res.data.data.schools
                if (schools) {
                    if (value === 'all') {
                        arr = schools
                    }
                    else {
                        for (const item of schools) {
                            const obj = {
                                text: item.name_en,
                                value: item.id
                            };
                            arr.push(obj)
                        }
                    }
                }
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取方案和学生相关的人员
     * id 方案id
     */
    getPlanRelationRoles(id, cb) {
        const type = 'get'
        const url = `/api/plans/${id}/get_plan_relation_roles.json`
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            id
        }
        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                let arr = [];
                const user_roles = res.data.data.user_roles
                if (user_roles) {
                    for (const item of user_roles) {
                        const obj = {
                            text: item.real_name,
                            value: item.id.toString()
                        };
                        arr.push(obj)
                    }
                }
                cb(arr);
            }
            else {
                notification.error({
                    description: res.data.status.message
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    /**
     * 获取学生详情
     * student_id 学生id
     */
    getStudents(student_id, cb, params_name = 'id') {
        const type = 'get'
        const url = `/api/students/${student_id}.json`
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            [params_name]: student_id
        }
        if (student_id) {
            Utils.httpHelper.REQUEST(url, params, type).then((res) => {
                if (res.data.status.code === '20000') {
                    cb(res.data.data);
                }
                else {
                    notification.error({
                        description: res.data.status.message
                    })
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }
}

let requestHelper = new RequestHelper()
export { requestHelper }
