/**
 * Created by accidie on 16/10/12.
 */
import '@babel/polyfill'
import ReactDOM from 'react-dom'
import { Router, browserHistory, hashHistory } from 'react-router'
import '../styles/common.scss'
import {Provider} from 'mobx-react/custom'
import { AppContainer } from 'react-hot-loader'
import Raven from 'raven-js'
import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
require('es6-promise/auto')
require('flexibility')
require('ahoy.js')

import  global from '../stores/global'
const stores = {global:new global()}

ahoy.trackAll()

const rootRoute = {
    childRoutes: [
        require('./app/cms').default,
        require('./app/opr').default,
        // {
        //     path: '/student',
        //
        //     childRoutes: [
        //         require('./app/student/Personal').default,
        //         require('./app/student/OrderManage').default,
        //         require('./app/student/Home').default,
        //     ],
        //     indexRoute: require('./app/student/Home').default
        // },
        require('./app').default,
        require('./app/Login').default,
        require('./app/ForgetPassword').default,
        // require('./app/NotFoundPage').default,

        // 协议
        require('./app/ServeClause').default
    ],
    indexRoute: require('./app').default
}

switch (process.env._ENV){
    case 'sit':
        Raven.config('https://c0d7035ce83d4279ae43f5fe47210419@sentry.doteetv.com.cn/9').install()
        break;
    case 'uat':
        Raven.config('https://799a9d66a103440982bb0a2572a506d2@sentry.doteetv.com.cn/10').install()
        break;
    case 'prod':
        Raven.config('https://1282b4639cff4ca0ad6f9123576c3807@sentry.doteetv.com.cn/11').install()
        break;
    default:
        break;
}

ReactDOM.render(
    <LocaleProvider locale={localStorage.getItem('language') === 'en-US' ? null : zhCN}>
        <AppContainer>
            <Provider {...stores}>
                <Router routes={rootRoute} history={browserHistory} />
            </Provider>
        </AppContainer>
    </LocaleProvider>,
    document.getElementById('app')
)
