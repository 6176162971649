/**
 * Created by accidie on 17/6/29.
 */
import { BackgroundFrame } from '@/component'

const Opr = props =>
    <div>
        <BackgroundFrame {...props}>
            {/* {props.children} */}
        </BackgroundFrame>
    </div>

export default Opr
