/**
 * Created by atyun on 18/1/3.
 */

// 发布、订阅
// 在两个不相关的组件，或者关系很远的组件之间通信可以使用这个
import { EventEmitter } from 'events'

let eventEmitter = new EventEmitter()

export {
    eventEmitter
}
