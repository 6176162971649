/**
 * Created by atyun on 17/5/24.
 */
export default {
    path: '/',

    getChildRoutes(partialNextState, cb) {
        // require.ensure([], (require) => {
            cb(null, [
                require('./Home').default,
                require('./Login').default,
                require('./ForgetPassword').default,
                require('./RegisterAgents').default,
            ])
        // })
    },

    getIndexRoute(location, cb) {
        // require.ensure([], (require) => {
            cb(null, require('./Home').default)
        // })
    },

    getComponent(nextState, cb) {
        // require.ensure([], (require) => {
            cb(null, require('./App').default)
        // })
    }
}