/**
 * Created by accidie on 16/10/31.
 */

const ApiConfig = {
    // api_url: 'http://dit.boko.liuyangbao.com/',
    api_url: '',

    // dit
    api_uid: '2642d4c1ebed62755b352d2b6a42c9096372450370b387c56f158423e6612552',
    api_secret: '17ec1e2ead9b396da8463723b979664d8b2d8d0e7712fafcc97f154c7f0b5d41'
}

export default ApiConfig
