/**
 * Created by accidie on 16/10/26.
 */
export default {
    path: 'login',

    getComponent(nextState, cb) {
        // require.ensure([], (require) => {
            cb(null, require('./LoginForm').default)
        // })
    }
}
