/**
 * Created by atyun on 16/11/2.
 */

class CheckHelper {
    constructor() {
        this.SpecialEmaileRegex = /^\w+([-.+]\w*)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        this.EmailRegex = /^(\w)[\w\-]+(\.\w+)*@([A-Za-zd0-9]+[-.])+[A-Za-zd]{2,4}$/
        this.PhoneRegex = /(^1(3[5-9]|47|5[012789]|78|73|8[23478])\d{8}$|134[0-8]\d{7}$)|(^1(8[019]|77)\d{8}$|1349\d{7}$)|(^1(3[0-2]|45|5[56]|76|8[56])\d{8}$)|(^1[35]3\d{8}$)|(^17[059]\d{8}$)|(^199\d{8}$)|(^140\d{8}$)/
        this.OnePhoneRegex = /^1\d{10}$/
        this.TelephoneRegex = /^(0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/
        this.URLRegex = /^(http|https):\/\/[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]':+!]*([^<>""])*$/
        this.HasNumberRegex = /^.*\d+.*$/
        this.IDCardRegex = /^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/
        this.PassWordRegex = /^(?![0-9]+$)(?![a-zA-Z]+$)(?!([^(0-9a-zA-Z)]|[\\(\\)])+$)([^(0-9a-zA-Z)]|[\\(\\)]|[a-zA-Z]|[0-9]){6,12}$/
        this.numberType = /^[0-9]+([.]{1}[0-9]+){0,1}$/
        this.positiveMumber = /^[0-9]*[1-9][0-9]*$/
        this.PositiveIntegerRegex = /^(0|[1-9][0-9]*)$/
    }

    // 是否为空
    isNull(value) {
        if(value == null){
            return true;
        }

        if (value === '') {
            return true
        }

        if (value.length === 0) {
            return true
        }


        return false
    }

    // 对象是否为空
    isEmpty(obj) {
        for (const i in obj) {
            if (obj.hasOwnProperty(i)) {
                return false
            }
        }

        return true
    }

    //收否有数字
    hasNum(num){
        if (num < 0){
            return false
        } 
        return true
    }

    // 是否满18岁
    isAudlt(birthday){
        const current_date = new Date()
        const birthday_date = new Date(birthday)

        let cal_year = current_date.getFullYear()-birthday_date.getFullYear()
        let cal_month = current_date.getMonth()-birthday_date.getMonth()
        let cal_day = current_date.getDate()-birthday_date.getDate()

        if(cal_year === 18){
            if(cal_month > 0){
                return true
            }

            if(cal_month < 0){
                return false
            }

            return cal_day >= 0
        }

        return cal_year > 18
    }

    // 比较两个数字是否相等，因为0.1+0.2 !== 0.3,所以简单的===比较，在极少的情况下是不正确的
    numbersCloasEnoughToEqual(n1,n2){
        if(!Number.EPSILON){
            Number.EPSILON = Math.pow(2,-52)
        }
        return Math.abs(n1-n2)<Number.EPSILON
    }

    // 最小长度验证
    min_length_validation(params, num) {
        if (params.length < num) {
            return { result: false, message: `长度小于${num}位` }
        }
        return { result: true }
    }

    // 最大长度验证
    max_length_validation(params, num) {
        if (params.length > num) {
            return { result: false, message: `长度大于${num}位` }
        }
        return { result: true }
    }

    // 正则表达式验证
    regex_validation(params, reg) {
        if (!reg.test(params)) {
            return { result: false, message: '格式不正确' }
        }

        return { result: true }
    }

    // 身份证号验证
    is_IDCard(value) {
        return this.IDCardRegex.test(value)
    }

    // 手机验证
    is_phone_validation(params) {
        if (!this.isPhone(params)) {
            return { result: false, message: '格式不正确' }
        }
        return { result: true }
    }

    // 邮箱验证
    is_email_validation(params) {
        if (!this.isEmail(params)) {
            return { result: false, message: '格式不正确' }
        }
        return { result: true }
    }

    // 解析约束规则，返回function数组
    analy = (obj) => {
        const funcs = []

        if (obj.min_length) {
            const func = (params) => this.min_length_validation(params, obj.min_length)
            funcs.push(func)
        }

        if (obj.max_length) {
            const func = (params) => this.max_length_validation(params, obj.max_length)
            funcs.push(func)
        }

        if (obj.RegEx) {
            const func = (params) => this.regex_validation(params, obj.RegEx)
            funcs.push(func)
        }

        if (obj.type && obj.type === 'phone') {
            const func = (params) => this.is_phone_validation(params, obj.RegEx)
            funcs.push(func)
        }

        if (obj.type && obj.type === 'email') {
            const func = (params) => this.is_email_validation(params, obj.RegEx)
            funcs.push(func)
        }

        return funcs
    }

    // 是否包含数字
    isHasNumber(value) {
        return this.HasNumberRegex.test(value)
    }

    // 是否为整数或小数
    isNumberType(value) {
        return this.numberType.test(value)
    }

    // 是否为整数
    isPositiveNumberType(value) {
        return this.positiveMumber.test(value)
    }

    // 输入的是否是中文
    inputIsChina(value) {
        if(!value) return false
        if(/.*[\u4e00-\u9fa5]+.*$/.test(value)){
            return false
        }
        return true
    }

    // 密码验证规则（包含数字，字母。字符任意两种组合）
    PasswordRule(value) {
        return this.PassWordRegex.test(value)
    }
    
    //是否是邮箱并且只能输入一个邮箱
    isOneEmail(value) {
        return this.SpecialEmaileRegex.test(value)
    }

    // 是否是邮箱
    isEmail(value,type) {
        // if(type){
            let is_email = true
            if(value.includes(';')){
                const str = value.split(';')
                str.forEach((obj) => {
                    if(!this.SpecialEmaileRegex.test(obj)){
                        is_email = false
                    }
                })
            }
            else{
                is_email = this.SpecialEmaileRegex.test(value)
            }
            return is_email
        // }else{
        //     return this.SpecialEmaileRegex.test(value)
        // }
    }

    // 是否是电话号码
    isTelephoneRegex(value) {
        return this.TelephoneRegex.test(value)
    }

    // 是否是手机号码
    isPhone(value) {
        // return this.PhoneRegex.test(value)
        return this.OnePhoneRegex.test(value)
    }

    // 是否是一个链接
    isURLRegex(value) {
        return this.URLRegex.test(value)
    }

    // 清除空格
    removeBlank(value) {
        return value.replace(/\s+/g, "")
    }

    // 判断是否为正整数 + 0
    isZEROAndPositiveInteger(value) {
        return this.PositiveIntegerRegex.test(value)
    }

}

let checkHelper = new CheckHelper()
export {checkHelper}
