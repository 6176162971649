/**
 * Created by accidie on 17/6/29.
 */
export default {
    path: 'cms',
    breadcrumbName: '首页',

    onEnter: (params, replace) => {
        if (Cookies.get('current_role') == 'student') {
            replace({ pathname: '/login' })
            return
        }

        if (!params.location.pathname.includes('/cms/service/pay_the_fees/pay_state')) {
            if (Cookies.get('current_role') !== 'cms') {
                Cookies.get('user_session_key') ? replace({ pathname: '/opr' }) : replace({ pathname: '/login' })
            }
        }
    },

    getChildRoutes(partialNextState, cb) {
        require.ensure([], require =>
          cb(null, [
              require('./Service').default,
              require('./Announcement').default,
              require('./Students').default,
              require('./Apply').default,
              require('./Personal').default,
              require('./NoCommit').default,
              require('./Settings').default,
              require('./HelpDetails').default,
              require('./Service/PayTheFees/PaymentLink').default,
              require('./Service/PayTheFees/OrderDetails').default,
              require('./Service/OrderManage/StudentPayment').default,
              require('./Service/PayTheFees/PayState').default,
              require('./Home').default,
              require('./VisaStudentPreview').default,
              require('./OverseasStudentPreview').default,
              require('./VisaApply').default,
              require('./FileCenter').default,
              require('./Finance').default,
              require('./Empty').default
          ])
        )
    },

    getIndexRoute(location, cb) {
        // require.ensure([], (require) => {
            cb(null, require('./Home').default)
        // })
    },

    getComponent(nextState, cb) {
        require.ensure([], require =>
           cb(null, require('./Cms').default)
        )
    }
}

