/**
 * Created by accidie on 16/10/19.
 */

import { Link } from 'react-router'
import { Checkbox, notification } from 'antd'
import Loadable from 'react-loadable'

import styles from './login.scss'
import { TextInput } from '@/component'
import Vcode from 'react-vcode'
import { Base64 } from 'js-base64'

const BackgroundHeader = Loadable({
    loader: () => import(/* webpackChunkName: "header" */'@/component/BackgroundFrame/BackgroundHeader'),
    loading: () => <Spin></Spin>,
});

const BackgroundFooter = Loadable({
    loader: () => import(/* webpackChunkName: "footer" */'@/component/BackgroundFrame/BackgroundFooter'),
    loading: () => <Spin></Spin>,
});

const Sidebar = Loadable({
    loader: () => import(/* webpackChunkName: "sidebar" */'@/component/Sidebar/Sidebar'),
    loading: () => <Spin></Spin>,
});

class LoginForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            account: '',
            password: '',
            validate_code: '',
            account_status: '',
            password_status: '',
            validate_code_status: '',
            agreement: false,
            account_text: '',
            password_text: '',
            validate_code_text: '',

            verify_code_value: '',
            error_time: 0,
            password_type: 'password'
        }
        this.login_ready_click = false
    }

    // 字段改变
    handleChange = (name, ev) => {
        if (name === 'checkbox') {
            this.setState({ agreement: !this.state.agreement })
        } else {
            const value = ev.target?ev.target.value:ev
            this.setState({ [name]: value })
        }
    }

    // 字段规则验证
    validations = () => {
        const params = {
            account: this.state.account,
            password: this.state.password,
            account_text: this.state.account,
            password_text: this.state.password
        }

        if (Utils.checkHelper.isNull(params.account)) {
            this.setState({ account_status: 'warn', account_text: '请输入邮箱或手机号' })
            return false
        } else if (!Utils.checkHelper.isPhone(params.account) && !Utils.checkHelper.isEmail(params.account)) {
            // this.setState({ account_status: 'error', account_text: '请输入正确的邮箱或手机号' })
            // return false
        } else {
            this.setState({ account_status: 'success' })
        }

        if (Utils.checkHelper.isNull(params.password)) {
            this.setState({ password_status: 'warn', password_text: '请输入6~12位登录密码' })
            return false
        } else {
            this.setState({ password_status: '' })
        }

        return true
    }

    // 得到用户角色
    getUserRole = (session_key) => {
        const type = 'get'
        const url = '/api/atyun_permission/roles/search_user_role_type.json'
        const params = {
            user_session_key: session_key
        }
        Utils.httpHelper.REQUEST(url, params, type).then((res) => {
            if(res.data.status.code === '20000'){
                if(res.data.data.type == "学生" || res.data.data.type == ""){
                    Cookies.set('current_role', 'student')
                    notification.warn({
                        description: '该账号无法在此平台登录'
                    })
                    this.login_ready_click = false
                    return 
                }

                notification.success({
                    description: '登录成功'
                })
                if (res.data.data.type && res.data.data.type === '代理') {
                    Cookies.set('current_role', 'cms')
                    this.context.router.push('/cms')
                } else if(res.data.data.type && res.data.data.type === '运营'){
                    Cookies.set('current_role', 'opr')
                    this.context.router.push('/opr')
                }else{
                    // Cookies.set('current_role', 'student')
                    // Cookies.set('first_enter', true)
                    // this.context.router.push('/student/order_manage')
                }
            }else{
                notification.warn({
                    description: res.data.status.message
                })
            }


        }).catch((e) => {
            console.log(e)
        })
    }

    // 是否被记住密码
    ifRemePass = () => {
        const is_reme = Utils.dataHelper.getCookies('is_reme')
        if (is_reme === '1') {
            const account = Base64.decode(Utils.dataHelper.getCookies('name'))
            const password = Base64.decode(Utils.dataHelper.getCookies('pass'))
            const agreement = true
            this.setState({
                account,
                password,
                agreement
            })
        }
    }

    componentWillMount() {
        if (Cookies.get('user_id') && Cookies.get('current_role') && Cookies.get('is_reme')!=='0') {
            // notification.warn({
            //     description: '您已经登录'
            // })
            
            this.context.router.push(`/${Cookies.get('current_role')}`)
        }else{
            this.ifRemePass()
        }
    }

    // 登录提交=
    handleSubmit = () => {
        const type = 'post'
        const url = '/api/users/sign_in.json'
        const params = {
            account: this.state.account.toLocaleLowerCase(),
            password: this.state.password
        }

        if (!this.validations()) {
            return false
        }

        if(this.state.error_time>=5){
            if(!this.state.validate_code){
                notification.warn({
                    description: '请输入验证码'
                })
                return false
            }

            if(this.state.validate_code !== this.state.verify_code_value){
                notification.warn({
                    description: '验证码不正确'
                })
                return false
            }
        }

        if (this.state.agreement) {
            Cookies.set('is_reme', '1',{ expires: 5 })
        } else {
            Cookies.set('is_reme', '0')
        }

        Cookies.set('name', Base64.encode(this.state.account), { expires: 5 })
        Cookies.set('pass', Base64.encode(this.state.password), { expires: 5 })

        if(this.login_ready_click){
            notification.warn({
                description: '正在登录，请勿频繁点击'
            })
            return false
        }

        const loginAction = () => {
            this.login_ready_click = true
            Utils.httpHelper.REQUEST(url, params, type).then((res) => {
                const status = res.data.status

                if (status.code === '20000') {
                    let user = res.data.data.user
                    // 返回的数据太长，cookie超过4096就存储不了，会导致报错，精简字段
                    user.roles = user.roles.map((obj)=>{
                        return {
                            id: obj.id,
                            name: obj.name,
                            name_cn: obj.name_cn,
                        }
                    })
                    let ary = ['current_sign_in_at', 'agent_special_password_digest', 'create_user_id',
                        'last_sign_in_at', 'former_user_id', 'pwd_exp', 'pwd_never_exp', 'pwd_tries', 'merchant_id', 'positions'];
                    ary.forEach(x => {
                        delete user[x]
                    })

                    Cookies.set('user_session_key', user.user_session_key)
                    // Cookies.set('user', JSON.stringify(user))
                    Utils.dataHelper.setCookie('user', JSON.stringify(user))
                    Cookies.set('user_id', user.id)
                    this.getUserRole(user.user_session_key)
                } else if (status.code === '40305') {
                    this.login_ready_click = false
                    window.localStorage.clear()
                    for (const o of ['user_session_key', 'current_role', 'user_id', 'user']) {
                        if (Cookies.get(o)) {
                            Cookies.remove(o)
                        }
                    }
                    loginAction()
                } else {
                    this.login_ready_click = false
                    let error_time = this.state.error_time
                    error_time++
                    this.setState({error_time})
                    notification.error({
                        description: res.data.status.message
                    })
                }
            }).catch((e) => {
                console.log(e)
            })
        }

        loginAction()
    }

    handleKeyUp = (e) => {
        if(e.keyCode === 13){
            this.handleSubmit()
        }
    }

    changePasswordType = () => {
        let password_type = this.state.password_type === 'password'?'text':'password'
        this.setState({password_type})
    }

    // 鼠标经过登录按钮时预加载首页框架
    onMouseOver = () => {
        BackgroundHeader.preload();
        BackgroundFooter.preload();
        Sidebar.preload();
    }

    render() {
        return (
            <div styleName="login--form">

                <section>
                    <h1>欢迎登录 AA留学线上申请平台</h1>

                    <div style={{height: '0'}}>
                        <input style={{visibility:'hidden'}} type="text" id="1"/>
                        <input style={{visibility:'hidden'}} type="password" id="0"/>
                    </div>

                    <div styleName="input--box">
                        <TextInput
                               name="账号"
                               placeholder="请输入手机号码/邮箱"
                               type="text"
                               value={this.state.account}
                               handleChange={(ev) => this.handleChange('account', ev)}
                               handleKeyUp={this.handleKeyUp}
                               status={this.state.account_status} text={this.state.account_text}
                        />

                    </div>

                    <div styleName="input--box">
                        <TextInput
                               name="密码"
                               placeholder="请输入账户密码"
                               showIcon
                               changeIcon={this.changePasswordType}
                               type={this.state.password_type}
                               value={this.state.password}
                               handleChange={(ev) => this.handleChange('password', ev)}
                               handleKeyUp={this.handleKeyUp}
                               status={this.state.password_status} text={this.state.password_text}
                        />
                    </div>

                    {
                        this.state.error_time >= 5 &&
                        <div styleName="input--box" style={{position:'relative'}}>
                            <TextInput
                                name="验证码"
                                placeholder="请输入验证码"
                                type="text"
                                value={this.state.validate_code}
                                handleChange={(ev) => this.handleChange('validate_code', ev)}
                                handleKeyUp={this.handleKeyUp}
                                status={this.state.validate_code} text={this.state.password_text}
                            />
                            <Vcode
                                style={{
                                    position:'absolute',
                                    backgroundColor: 'transparent',
                                    float: 'right',
                                    bottom: '0',
                                    right: '1rem',
                                    height: '3rem'
                                }}
                                onChange={(v) => this.handleChange('verify_code_value', v)}
                            />
                        </div>
                    }

                    <div styleName="input--text">
                        <Checkbox checked={this.state.agreement}
                                  onChange={(ev) => this.handleChange('checkbox', ev)}
                        >记住密码
                        </Checkbox>
                        <Link to="/forgetpassword"><span styleName="input--text_span">忘记密码?</span></Link>
                    </div>

                    <button onClick={this.handleSubmit} onMouseOver={this.onMouseOver}>登录</button>
                </section>

                {/*<div styleName='footer'><Link to="/">返回首页</Link></div>*/}
            </div>

        )
    }
}

LoginForm.contextTypes = {
    router: PropTypes.object
}

export default LoginForm
