import Loadable from 'react-loadable'
import { Spin, Divider } from 'antd'
const placeholder = () => (null)

const AcademicAndLanguage = Loadable({ loader: () => import('./AcademicAndLanguage/AcademicAndLanguage'), loading: placeholder});
const AddNewStudents = Loadable({ loader: () => import('./AddNewStudents/AddNewStudents'), loading: placeholder});
const Amonash = Loadable({ loader: () => import('./AutoApplication/Amonash/Amonash'), loading: placeholder});
const Sydney = Loadable({ loader: () => import('./AutoApplication/Sydney/Sydney'), loading: placeholder});
const Aunsw = Loadable({ loader: () => import('./AutoApplication/Aunsw/Aunsw'), loading: placeholder});
const BackgroundHeader = Loadable({ loader: () => import('./BackgroundFrame/BackgroundHeader'), loading: placeholder});
const BackgroundFooter = Loadable({ loader: () => import('./BackgroundFrame/BackgroundFooter'), loading: placeholder});
const BackgroundFrame = Loadable({ loader: () => import('./BackgroundFrame/BackgroundFrame'), loading: placeholder});
const Button = Loadable({ loader: () => import('./Button/Button'), loading: placeholder});
const ButtonGroupContainer = Loadable({ loader: () => import('./ButtonGroupContainer/ButtonGroupContainer'), loading: placeholder});
const CalendarComponent = Loadable({ loader: () => import('./CalendarComponent/CalendarComponent'), loading: placeholder});
const CommonPayment = Loadable({ loader: () => import('./CommonPayment/CommonPayment'), loading: placeholder});
const VisaPayment = Loadable({ loader: () => import('./CommonPayment/VisaPayment'), loading: placeholder});
const ComponentGroupContainer = Loadable({ loader: () => import('./ComponentGroupContainer/ComponentGroupContainer'), loading: placeholder});
const ConfirmChangeModal = Loadable({ loader: () => import('./ConfirmChangeModal/ConfirmChangeModal'), loading: placeholder});
const DownloadOffer = Loadable({ loader: () => import('./DownloadOffer/DownloadOffer'), loading: placeholder});
const DropDown = Loadable({ loader: () => import('./DropDown/DropDown'), loading: placeholder});
const Editor = Loadable({ loader: () => import('./Editor/Editor'), loading: placeholder, render(loaded, props) {
    let Component = loaded.default;
    // 由于 editor 使用 react-loadable 时ref无法使用，通过 render 来解决
    return <Component {...props} ref={props.refProp}/>;
}});
const HeaderLabel = Loadable({ loader: () => import('./HeaderLabel/HeaderLabel'), loading: placeholder});
const InputRadio = Loadable({ loader: () => import('./InputRadio/InputRadio'), loading: placeholder});
const MonthRangePicker = Loadable({ loader: () => import('./Inputs/DateAndTimePicker/MonthRangePicker'), loading: placeholder});
const YearRangePicker = Loadable({ loader: () => import('./Inputs/DateAndTimePicker/YearRangePicker'), loading: placeholder});
const ShowEditDate = Loadable({ loader: () => import('./Inputs/DateAndTimePicker/ShowEditDate'), loading: placeholder});
const SpanInputDate = Loadable({ loader: () => import('./Inputs/DateAndTimePicker/SpanInputDate'), loading: placeholder});
const LabelLeftInput = Loadable({ loader: () => import('./Inputs/Input/LabelLeftInput'), loading: placeholder});
const LabelTopInput = Loadable({ loader: () => import('./Inputs/Input/LabelTopInput'), loading: placeholder});
const LabelTopInputSpan = Loadable({ loader: () => import('./Inputs/Input/LabelTopInputSpan'), loading: placeholder});
const PercentageRangeInput = Loadable({ loader: () => import('./Inputs/Input/PercentageRangeInput'), loading: placeholder});
const PWDInput = Loadable({ loader: () => import('./Inputs/Input/PWDInput'), loading: placeholder});
const SpanInput = Loadable({ loader: () => import('./Inputs/Input/SpanInput'), loading: placeholder});
const TextAreaInput = Loadable({ loader: () => import('./Inputs/Input/TextAreaInput'), loading: placeholder});
const TextInput = Loadable({ loader: () => import('./Inputs/Input/TextInput'), loading: placeholder});
const LabelTextArea = Loadable({ loader: () => import('./Inputs/Others/LabelTextArea'), loading: placeholder});
const LabelValue = Loadable({ loader: () => import('./Inputs/Others/LabelValue'), loading: placeholder});
const LabelValueList = Loadable({ loader: () => import('./Inputs/Others/LabelValueList'), loading: placeholder});
const SearchListInput = Loadable({ loader: () => import('./Inputs/Others/SearchListInput'), loading: placeholder});
const Loading = Loadable({ loader: () => import('./Loading/Loading'), loading: placeholder});
const LookDetails = Loadable({ loader: () => import('./LookDetails/LookDetails'), loading: placeholder});
const MessageBoard = Loadable({ loader: () => import('./MessageBoard/MessageBoard'), loading: placeholder});
const ModalHelp = Loadable({ loader: () => import('./ModalHelp/ModalHelp'), loading: placeholder});
const PlanDetailContainer = Loadable({ loader: () => import('./PlanDetailContainer/PlanDetailContainer'), loading: placeholder});
const ApplyProgressBar = Loadable({ loader: () => import('./ProgressBar/ApplyProgressBar'), loading: placeholder});
const ApplyDetailProgressBar = Loadable({ loader: () => import('./ProgressBar/ApplyDetailProgressBar'), loading: placeholder});
const ProgressBar = Loadable({ loader: () => import('./ProgressBar/ProgressBar'), loading: placeholder});
const RecordList = Loadable({ loader: () => import('./RecordList/RecordList'), loading: placeholder});
const SelectComponent = Loadable({ loader: () => import('./SelectComponent/SelectComponent'), loading: placeholder});
const SexChoose = Loadable({ loader: () => import('./SexChoose/SexChoose'), loading: placeholder});
const Sidebar = Loadable({ loader: () => import('./Sidebar/Sidebar'), loading: placeholder});
const Step = Loadable({ loader: () => import('./Step/Step'), loading: placeholder});
const TableComponent = Loadable({ loader: () => import('./Table/TableComponent'), loading: placeholder});
const Tag = Loadable({ loader: () => import('./Tag/Tag'), loading: placeholder});
const Tip = Loadable({ loader: () => import('./Tip/Tip'), loading: placeholder});
const TopTagGroup = Loadable({ loader: () => import('./TopTagGroup/TopTagGroup'), loading: placeholder});
const TreeDiagram = Loadable({ loader: () => import('./TreeDiagram/TreeDiagram'), loading: placeholder});
const TreeTable = Loadable({ loader: () => import('./TreeTable/TreeTable'), loading: placeholder});
const UniformFileComponent = Loadable({ loader: () => import('./UniformFileComponent/UniformFileComponent'), loading: placeholder});
const Upload = Loadable({ loader: () => import('./Upload/Upload'), loading: placeholder});
const UploadComponent = Loadable({ loader: () => import('./UploadComponent/UploadComponent'), loading: placeholder});
const WelcomePage = Loadable({ loader: () => import('./WelcomePage/WelcomePage'), loading: placeholder});
const AutoComplete = Loadable({ loader: () => import('./AutoComplete/AutoComplete'), loading: placeholder});
const WithSafeSetState = Loadable({ loader: () => import('./WithSafeSetState/WithSafeSetState'), loading: placeholder});
const YearPicker = Loadable({ loader: () => import ('./YearPicker'), loading: placeholder})
export {
    YearRangePicker,
    YearPicker,
    AcademicAndLanguage,
    AddNewStudents,
    Amonash,
    Sydney,
    Aunsw,
    BackgroundHeader,
    BackgroundFooter,
    BackgroundFrame,
    Button,
    ButtonGroupContainer,
    CalendarComponent,
    CommonPayment,
    VisaPayment,
    ComponentGroupContainer,
    ConfirmChangeModal,
    DownloadOffer,
    DropDown,
    Editor,
    HeaderLabel,
    InputRadio,
    MonthRangePicker,
    ShowEditDate,
    SpanInputDate,
    LabelLeftInput,
    LabelTopInput,
    LabelTopInputSpan,
    PercentageRangeInput,
    PWDInput,
    SpanInput,
    TextAreaInput,
    TextInput,
    LabelTextArea,
    LabelValue,
    LabelValueList,
    SearchListInput,
    Loading,
    LookDetails,
    MessageBoard,
    ModalHelp,
    PlanDetailContainer,
    ApplyProgressBar,
    ApplyDetailProgressBar,
    ProgressBar,
    RecordList,
    SelectComponent,
    SexChoose,
    Sidebar,
    Step,
    TableComponent,
    Tag,
    Tip,
    TopTagGroup,
    TreeDiagram,
    TreeTable,
    UniformFileComponent,
    Upload,
    UploadComponent,
    WelcomePage,
    AutoComplete,
    WithSafeSetState
}
