/**
 * Created by accidie on 17/6/29.
 */
 
export default {
    path: 'opr',
    breadcrumbName: '首页',

    onEnter: (params, replace) => {
        if(Cookies.get('current_role') == 'student'){
            replace({ pathname: '/login' })
            return
        }

        if(Cookies.get('current_role') !== 'opr'){
            Cookies.get('user_session_key') ? replace({ pathname: '/cms' }) : replace({ pathname: '/login' })
        }
    },

    getChildRoutes(partialNextState, cb) {
        require.ensure([], require =>
            cb(null, [
                require('./Service').default,
                require('./SystemManage').default,
                require('./Students').default,
                require('./Apply').default,
                require('./Personal').default,
                require('./Finance').default,
                require('./AgentManagement').default,
                require('./Home').default,
                require('./NoCommit').default,
                require('./NoticeDetails').default,
                require('./Announcement').default,
                require('./AnnouncementList').default,
                require('./HelpDetails').default,
                require('./StatisticalReport').default,
                require('./CollegeManagement').default,
                require('./position').default,
                require('./OverseasStudentPreview').default,
                require('./VisaStudentPreview').default,
                require('./VisaApply').default,
                require('./PostTask').default,
                require('./ServiceOrder').default,
                require('./DataDictionary').default,
                require('./FileCenter').default,
                require('./ContractManager').default,
                require('./KnowledgeLibrary').default,
                require('./MailManagement').default,
                require('./Empty').default,
                require('./WorkReportManagement').default,
                require('./RecentConversations').default
            ])
        )
    },

    getIndexRoute(location, cb) {
        // require.ensure([], (require) => {
            cb(null, require('./Home').default)
        // })
    },

    getComponent(nextState, cb) {
        // require.ensure([], require =>
            cb(null, require('./Opr').default)
        // )
    }
}