/**
 * Created by accidie on 16/10/31.
 */
import axios from 'axios'
import ApiConfig from './constant/api'
import { notification } from 'antd'
import { Base64 } from 'js-base64'
import Cable from 'actioncable'
import { or } from 'ip';
// import { isPropertyConfigurable } from 'mobx/lib/utils/utils';
// import MD5 from 'blueimp-md5'
const uuidV4 = require('uuid/v4')

let url;
let isRefreshing = false;
let refreshSubscribers = [];

const instance = axios.create({
    baseURL: ApiConfig.api_url
})

switch (process.env._ENV){
  case 'dit':
    url = 'wss://dit-boko.liuyangbao.com/cable'
    break;
  case 'sit':
    url = 'wss://sit-boko.liuyangbao.com/cable'
    break;
  case 'uat':
    url = 'wss://uat-boko.liuyangbao.com/cable'
    break;
  case 'prod':
    url = 'wss://boko.liuyangbao.com/cable'
    break;
  case 'sit2':
    url = 'wss://sit2-boko.liuyangbao.com/cable'
    break;
  default:
    url = 'ws://localhost:4000/cable'
    break;
}

class HttpHelper {
    // 静态常量
    static staticProperty = {
        cable: Cable.createConsumer(url)
    }

    constructor() {
        this.axiosInterceptor(instance);
    }

    /**
     * 获取token
     */
    getToken() {
        const url = `/${ApiConfig.api_url}oauth/token.json?`
        const params = {
            client_id: ApiConfig.api_uid,
            client_secret: ApiConfig.api_secret,
            grant_type: 'client_credentials'
        }
        return instance.post(url, params)
    }

    /**
     * 自定义封装请求
     * @param url
     * @param params
     * @param type
     * @returns {*}
     * @constructor
     */
    REQUEST(url, params, type) {
        return this.requestBody(url, params, type);
    }

    /**
     *
     * @param {*} url
     * @param {*} params
     * @param {*} type
     */
    requestBody(url, params, type) {

        const token = JSON.parse(localStorage.getItem('token'))
        const language = localStorage.getItem('language')

        params['access_token'] = token ? token.access_token : ''
        params['locale'] = language === 'en-US'? 'en' : 'zh-CN'//TODO if need support several languages

        switch (type) {
            case 'get':
                // user_session_key失效时，取得cookie中的账户重新获取session_key,然后再次发请求
                return instance.get(url, { params }).then((res)=>{
                    if(res.data.status.code === '50001' && Cookies.get('name') && Cookies.get('pass')){
                        return this.signIn().then((status)=>{
                            return status && instance.get(url, { params })
                        })
                    }
                    return res
                })
            case 'post':
                return instance.post(url, params).then((res)=>{
                    if(res.data.status.code === '50001' && Cookies.get('name') && Cookies.get('pass')){
                        return this.signIn().then((status)=>{
                            return status && instance.post(url, params)
                        })
                    }
                    return res
                })
            case 'put':
                return instance.put(url, params).then((res)=>{
                    if(res.data.status.code === '50001' && Cookies.get('name') && Cookies.get('pass')){
                        return this.signIn().then((status)=>{
                            return status && instance.put(url, params)
                        })
                    }
                    return res
                })
            case 'delete':
                return instance.delete(url, { params }).then((res)=>{
                    if(res.data.status.code === '50001' && Cookies.get('name') && Cookies.get('pass')){
                        return this.signIn().then((status)=>{
                            return status && instance.delete(url, { params })
                        })
                    }
                    return res
                })
            case 'patch':
                return instance.patch(url, params).then((res)=>{
                    if(res.data.status.code === '50001' && Cookies.get('name') && Cookies.get('pass')){
                        return this.signIn().then((status)=>{
                            return status && instance.patch(url, params)
                        })
                    }
                    return res
                })
        }
    }

    customRequest(config){
        return instance.request(config).then(res=>{
            // if(res.data.status.code === '50001' && Cookies.get('name') && Cookies.get('pass')){
            //     return this.signIn().then((status)=>{
            //         return status && instance.request(config)
            //     })
            // }
            return res
        })
    }

    /**
     * request interceptor
     * @param config
     */
    requestInterceptor(config) {
        // console.log('request!!!!!!!!!!!!!!!!!!!!!!!!!', config.url);
        const token = JSON.parse(localStorage.getItem('token'))
        // token request
        if(config.url === `/${ApiConfig.api_url}oauth/token.json?`) return config;
        // token expired
        else if (token === null || !token.access_token || token.created_at + token.expires_in < new Date().getTime() / 1000) {
            // use isRefreshing adjust status
            if(!isRefreshing) {
                isRefreshing = true;
                this.getToken()
                    .then(res => {
                        const tokenObj = {
                            created_at: res.data.created_at,
                            expires_in: res.data.expires_in,
                            access_token: res.data.access_token
                        }
                        localStorage.setItem('token', JSON.stringify(tokenObj));
                        // resolve request between get token
                        this.onRrefreshed(res.data.access_token)
                    })
            }
            const retryOrigReq = new Promise((resolve, reject) => {
                this.subscribeTokenRefresh((newToken) => {
                    // replace the expired token and retry
                    if(['/api/users/sign_in.json', '/api/send_code.json', '/api/users/send_email_code.json'].includes(config.url)) {
                        if(config.data){
                            config.data.access_token = newToken;
                        }else{
                            config.params.access_token = newToken;
                        }
                    }
                    else {
                        if(config.params){
                            config.params['access_token'] = newToken;
                        }else{
                            config.data.access_token = newToken;
                        }
                    }
                    resolve(config);
                });
            });
            return retryOrigReq;
        }

        return config;
    }

    /**
     * response interceptor
     * @param response
     */
    responseInterceptor(response) {
        // console.log('response !!!!!!!!!!!!!!!!!!!!!!!!', response);
        return response;
    }

    /**
     * axios interceptors
     */
    axiosInterceptor(axios) {
        // request token interceptor
        axios.interceptors.request.use((config) => this.requestInterceptor(config), (error) => {
            return Promise.reject(error);
        })

        // response interceptor
        axios.interceptors.response.use((response) => this.responseInterceptor(response), (error) => {
            return Promise.reject(error);
        });
    }

    /**
     * subscribe need refresh URL
     * @param {callback} cb
     */
    subscribeTokenRefresh(cb) {
        refreshSubscribers.push(cb);
    }

    /**
     * refresh URLs
     * @param {} token
     */
    onRrefreshed(token) {
        refreshSubscribers.map(cb => {
            cb(token);
        });
        refreshSubscribers = [];
        isRefreshing = false;
    }

    signIn(){
        const type = 'post'
        const url = '/api/users/sign_in.json'
        const params = {
            account: Base64.decode(Cookies.get('name')),
            password: Base64.decode(Cookies.get('pass'))
        }

        return this.REQUEST(url, params, type).then((res) => {
            const status = res.data.status
            if (status.code === '20000') {
                const user = res.data.data.user
                Cookies.set('user_session_key', user.user_session_key)
                return true
            }
            else{
                notification.error({
                    description: '用户信息验证失败，请重新登录'
                })
                window.sessionStorage.clear()
                for (const o of ['user_session_key', 'current_role', 'user_id', 'user']) {
                    if (Cookies.get(o)) {
                        Cookies.remove(o)
                    }
                }
                localStorage.removeItem('token')
                localStorage.removeItem('change_name')
                // 退出隐藏企业QQ
                if($('.class_qidian_wpa')){
                    $('.class_qidian_wpa').hide()
                }
                window.setTimeout(function() {window.location.href='/';},2000)
                return false
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    // 将对象或数组转换成formdata的格式
    objectToFormData (obj, form, namespace) {
        var fd = form || new FormData();
        var formKey;
        if(obj instanceof Array){
            for(var item of obj ){
                if(typeof item === 'object' && !(item instanceof File)){
                    this.objectToFormData(item, fd, `${namespace}[]`);
                }else{
                    fd.append(`${namespace}[]`,item)
                }

            }
        }else{
            for(var property in obj) {
                if(obj.hasOwnProperty(property) && obj[property]) {

                    if(namespace) {
                        formKey = namespace + '[' + property + ']';
                    } else {
                        formKey = property;
                    }

                    // if the property is an object, but not a File,
                    // use recursivity.
                    if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                        this.objectToFormData(obj[property], fd, formKey);
                    } else {

                        // if it's a string or a File object
                        fd.append(formKey, obj[property]);
                    }

                }
            }
        }
        return fd;

    };

    /**
     * 上传文件请求
     * @param url   请求地址(string)
     * @param params  请求参数（obj）
     * @param type   请求类型(string)
     * @param callback   回调函数（util）
     */
    uploadFile(url, params, type, callback) {
        const token = JSON.parse(localStorage.getItem('token'))
        if (token === null || !token.access_token || token.created_at + token.expires_in < new Date().getTime() / 1000) {
            return this.getToken().then((response) => {
                const data = response.data
                const tokenObj = {
                    created_at: data.created_at,
                    expires_in: data.expires_in,
                    access_token: data.access_token
                }

                localStorage.setItem('token', JSON.stringify(tokenObj))
                const formData = new FormData()
                const xhr = new XMLHttpRequest()

                formData.append('access_token', data.access_token)
                let resultdata = this.objectToFormData(params,formData)

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        callback(JSON.parse(xhr.response))
                    }
                }

                xhr.open(type, url, true)
                xhr.setRequestHeader('Content-Type', 'multipart/form-data')
                xhr.send(resultdata)
            }).catch((res) => {
                console.log(res)
            })
        } else {
            const formData = new FormData()
            const xhr = new XMLHttpRequest()

            formData.append('access_token', token.access_token)
            let resultdata = this.objectToFormData(params,formData)

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    callback(JSON.parse(xhr.response))
                }
            }

            xhr.open(type, url, true)
            xhr.send(resultdata)
        }
    }


    uploadFiles(url, params, files = {}, name, type = 'post') {
        const token = JSON.parse(localStorage.getItem('token'))
        if (token === null || !token.access_token || token.created_at + token.expires_in < new Date().getTime() / 1000) {
            return this.getToken().then((response) => {
                const data = response.data
                const tokenObj = {
                    created_at: data.created_at,
                    expires_in: data.expires_in,
                    access_token: data.access_token
                }

                localStorage.setItem('token', JSON.stringify(tokenObj))
                const formData = new FormData()
                const xhr = new XMLHttpRequest()
                for (const key in params) {
                    if (Array.isArray(params[key])) {
                        // 如果是数组\
                        params[key].forEach((v) => {
                            formData.append(`${key}[]`, v)
                        })
                    } else {
                        formData.append(key, params[key])
                    }
                }

                files.forEach((file) => {
                    formData.append(name, file)
                })
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        return new Promise((resolve, reject) => {
                            resolve(JSON.parse(xhr.response))
                        })
                    }
                }
                formData.append('access_token', data.access_token)
                xhr.open(type, url, true)
                xhr.send(formData)
            })
        } else {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                const xhr = new XMLHttpRequest()

                formData.append('access_token', token.access_token)

                for (const key in params) {
                    if (Array.isArray(params[key])) {
                        // 如果是数组\
                        params[key].forEach((v) => {
                            formData.append(`${key}[]`, v)
                        })
                    } else {
                        formData.append(key, params[key])
                    }
                }

                files.forEach((file) => {
                    formData.append(name, file)
                })
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        resolve(JSON.parse(xhr.response))
                    }
                }

                xhr.open(type, url, true)
                xhr.send(formData)
            })
        }
    }

    // 批量上传多组文件
    uploadatchFiles(url, params, files = {}, names, type = 'post') {
        const token = JSON.parse(localStorage.getItem('token'))
        if (token === null || !token.access_token || token.created_at + token.expires_in < new Date().getTime() / 1000) {
            return this.getToken().then((response) => {
                const data = response.data
                const tokenObj = {
                    created_at: data.created_at,
                    expires_in: data.expires_in,
                    access_token: data.access_token
                }

                localStorage.setItem('token', JSON.stringify(tokenObj))
                const formData = new FormData()
                const xhr = new XMLHttpRequest()
                for (const key in params) {
                    if (Array.isArray(params[key])) {
                        // 如果是数组\
                        params[key].forEach((v) => {
                            formData.append(`${key}[]`, v)
                        })
                    } else {
                        formData.append(key, params[key])
                    }
                }

                files.map((file, i) => {
                    file.map((item) => {
                        formData.append(names[i], item)
                    })
                })

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        return new Promise((resolve, reject) => {
                            resolve(JSON.parse(xhr.response))
                        })
                    }
                }
                formData.append('access_token', data.access_token)
                xhr.open(type, url, true)
                xhr.send(formData)
            })
        } else {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                const xhr = new XMLHttpRequest()

                formData.append('access_token', token.access_token)

                for (const key in params) {
                    if (Array.isArray(params[key])) {
                        // 如果是数组\
                        params[key].forEach((v) => {
                            formData.append(`${key}[]`, v)
                        })
                    } else {
                        formData.append(key, params[key])
                    }
                }

                files.map((file, i) => {
                    file.map((item) => {
                        formData.append(names[i], item)
                    })
                })
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        resolve(JSON.parse(xhr.response))
                    }
                }

                xhr.open(type, url, true)
                xhr.send(formData)
            })
        }
    }

    uploadFilesArrays(url, params, files = {}, contract_files = {},type = 'post') {
        const token = JSON.parse(localStorage.getItem('token'))
        if (token === null || !token.access_token || token.created_at + token.expires_in < new Date().getTime() / 1000) {
            return this.getToken().then((response) => {
                const data = response.data
                const tokenObj = {
                    created_at: data.created_at,
                    expires_in: data.expires_in,
                    access_token: data.access_token
                }

                localStorage.setItem('token', JSON.stringify(tokenObj))
                const formData = new FormData()
                const xhr = new XMLHttpRequest()
                for (const key in params) {
                    if (Array.isArray(params[key])) {
                        // 如果是数组\
                        params[key].forEach((v) => {
                            formData.append(`${key}[]`, v)
                        })
                    } else {
                        formData.append(key, params[key])
                    }
                }

                files.forEach((file) => {
                    formData.append('files[]', file)
                })

                contract_files.forEach((file) => {
                    formData.append('contract_files[]', file)
                })

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        return new Promise((resolve, reject) => {
                            resolve(JSON.parse(xhr.response))
                        })
                    }
                }
                formData.append('access_token', data.access_token)
                xhr.open(type, url, true)
                xhr.send(formData)
            })
        } else {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                const xhr = new XMLHttpRequest()

                formData.append('access_token', token.access_token)

                for (const key in params) {
                    if (Array.isArray(params[key])) {
                        // 如果是数组\
                        params[key].forEach((v) => {
                            formData.append(`${key}[]`, v)
                        })
                    } else {
                        formData.append(key, params[key])
                    }
                }

                files.forEach((file) => {
                    formData.append('files[]', file)
                })

                contract_files.forEach((file) => {
                    formData.append('contract_files[]', file)
                })

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        resolve(JSON.parse(xhr.response))
                    }
                }

                xhr.open(type, url, true)
                xhr.send(formData)
            })
        }
    }

    getFileKey() {
        const date = new Date()
        const year = date.getFullYear()
        const month = /\d/.test(date.getMonth()) ?  ('0' + (date.getMonth()+1)) : String(date.getMonth()+1)
        const day = /\d/.test(date.getDate()) ? ('0' + (date.getDate())) : String(date.getDate())
        const hour = /\d/.test(date.getHours()) ?  String(date.getHours()) : ('0' + date.getHours())
        const minute = /\d/.test(date.getMinutes()) ?  String(date.getMinutes()) : ('0' + date.getMinutes())
        const second = /\d/.test(date.getSeconds()) ?  String(date.getSeconds()) : ('0' + date.getSeconds())

        return year + month + day + hour + minute + second + uuidV4(4).match(/.{4}/)[0]
    }

    // 上传文件到七牛云上
    async uploadFileToQiniu(protocol, file_array, callback, nextStep) {
        const url_token = '/api/attachments/upload_token.json'
        const params_token = {
            user_session_key: Cookies.get('user_session_key')
        }
        let upload_token = ''
        let http_domain = ''
        let res = await this.REQUEST(url_token, params_token, 'get')
        if (res.data.status.code === '20000') {
            upload_token = res.data.data.upload_token
            http_domain = `https://${res.data.data.bucket_domain}/`
        } else if (res.data.status.code === '50000') {
            notification.error({ description: res.data.status.message })
        }

        // let url = ''
        // if (protocol === 'http') {
        //     url = 'http://upload.qiniu.com/'
        // }
        // if (protocol === 'https') {
        //     url = 'https://up.qbox.me/'
        // }
    
        let url = `${protocol}://upload.qiniup.com/`


        // const http_domain = 'https://uploads.liuyangbao.com/'
        // const http_domain = 'https://images.liuyangbao.com/'

        return Promise.all(file_array.map((obj, i) =>
            new Promise((resolve, reject) => {
                const ext = /\.\w*$/.exec(obj.name)[0]
                const file_key = obj.name.replace(ext, '').replace(/[^0-9a-zA-Z\u4e00-\u9fa5-_.:/]/g, '') + '_' + this.getFileKey() + ext
                const params = {
                    key: file_key,
                    token: upload_token,
                    'x:origin_filename': obj.name,
                    'x:online_filename': file_key,
                    'x:size': obj.size,
                    'x:file_content_type': obj.file_content_type,
                    file: obj.file
                }
                this.uploadFile(url, params, 'post', res => {
                        const upload_success = res['x:origin_filename'] && res['x:origin_filename'] === obj.name
                        const return_value = {
                            url: `${http_domain}${res.key}`,
                            // data: res,
                            success: upload_success,
                            origin_file: obj,
                            file_content_type: res['x:file_content_type']
                        }
                        if (!upload_success) {
                            return_value.message = `文件${obj.name}上传失败`
                        }
                        resolve(return_value)
                        // callback(return_value)
                    }
                )
            })
        ))
    }

    // 因为要获得token,所以写在这里
    downloadFile(url, params, file_ids) {
        const token = JSON.parse(localStorage.getItem('token'))
        let str = ''
        for (const item in params) {
            if (params.hasOwnProperty(item)) {
                if (Array.isArray(params[item])) {
                    params[item].forEach((v)=>{
                        let temp = v 
                        if(typeof v === 'object'){
                            temp = JSON.stringify(v)
                        }
                        str += `${item}[]=${temp}&`
                    })
                } else {
                    str += `${item}=${params[item]}&`
                }
            }
        }
        if (token === null || !token.access_token || token.created_at + token.expires_in < new Date().getTime() / 1000) {
            return this.getToken().then((response) => {
                const data = response.data
                const tokenObj = {
                    created_at: data.created_at,
                    expires_in: data.expires_in,
                    access_token: data.access_token
                }

                localStorage.setItem('token', JSON.stringify(tokenObj))
                str += `access_token=${data.access_token}`
            }).catch((res) => {
                console.log(res)
            })
        } else {
            str += `access_token=${token.access_token}`
        }
        console.log('this is download file url')
        console.log(params)
        console.log(`${location.origin}${url}?${str}`)
        window.open(`${location.origin}${url}?${str}`)
        
        if (Cookies.get('current_role') === 'cms' && file_ids != null) {
            file_ids.forEach(id => {
                this.countDownloadTimes(id)
            })
        }
    }

    countDownloadTimes(file_id){
       this.countDownloadOrPreviewTimes(file_id, 'download')
    }

    countPreviewTimes(file_id){
        this.countDownloadOrPreviewTimes(file_id, 'view')
    }

    countDownloadOrPreviewTimes(file_id, type){
        if (file_id) {
            const url = '/api/attachments/view_or_download.json';
            const params = {
                user_session_key: Cookies.get('user_session_key'),
                attachment_id: file_id,
                type: type
            }
            this.REQUEST(url, params, 'post')
        }
    }

    /**
     * websocket connect
     * @param callback   回调函数（util）
     * @param data  请求参数（string）
     */
   connectWebsocket(callback, type='default') {
        Cable.startDebugging()
        // 获取静态常量
        let cable = HttpHelper.staticProperty.cable
        if (!cable) {
            cable = Cable.createConsumer(url)
        }
        console.log('websocket 开始订阅');

        const subscription = cable.subscriptions.create(
            {
                channel: 'FileCenterChannel',
                user_id: Cookies.get('user') && JSON.parse(Cookies.get('user')).id,
                env: process.env._ENV,
                subscribe_type: type
            },
            {
                connected: () => {
                    console.log('=========  connected   ========');
                },
                received: (data) => {
                    console.log('=========   received   ========', data)
                    if (callback) {
                        callback(data)
                    }
                },
                disconnected: () => {
                    console.log('========= disconnected ========')
                }
            }
        );
        return subscription;
    }

    /**
     * websocket unsubscribe
     */
    unsubscribe(subscription) {
        if(subscription) {
            subscription.unsubscribe();
            console.log('websocket 取消订阅');
        } else console.error('subscription 对象未找到')
    }

    //PM25940 if the request data is too big, split it in several times
    /**
     * Be careful, it will return result ary directly
     * you should handle the result by yourself
     */
    async splitBigRequest(page, per, url, params, request_type){
        if(per >= 1000){
            let basic_per = 500;
            let times = per / basic_per;
            let page_from = times * (page - 1) + 1;
            let page_to = times * page;
            let res = [];
            for(let i=page_from; i<=page_to; i++){
                params.page = i;
                params.per = basic_per;
                // request_ary.push(this.REQUEST(url, Object.assign({}, params), request_type));
                let tmp = await this.REQUEST(url, Object.assign({}, params), request_type);
                res.push(tmp);
            }
            return res;
        }else{
            return this.REQUEST(url, params, request_type).then(res=>{
                return res;
            })
        }
    }
}


let httpHelper = new HttpHelper()
export {httpHelper}
