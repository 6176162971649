/**
 * Created by accidie on 16/10/17.
 */


import './app.scss'
import recordNumberIcon from '../../image/icons/recordNumberIcon.png'

const App = props => (
    <div styleName="wrap">
        <div styleName='home--container'>
            <header>
            <img src={require("../../image/bg-20230912.jpg")}
                 alt="background_img" />
                <div styleName="left">
                    {props.children}
                </div>

                <div styleName="right" >
                    <div>
                        {/*<img src={require('../../image/AANewLogo.png')}/>*/}
                        {/*<object data={require('../../image/AALogo.svg')} type="image/svg+xml"/>*/}
                    </div>
                </div>

                <footer>
                    <a href="https://beian.miit.gov.cn" target="_blank">
                        <span>沪ICP备16029944号-2</span>
                    </a>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102005960" target="_blank">
                        /&nbsp;<img src={recordNumberIcon} style={{ float: "none", 'verticalAlign': 'middle' }} />
                        <span>沪公网安备 31010102005960号</span>
                    </a>
                    <span>Copyright@2016 liuyangbao.com All Rights Reserved</span>
                </footer>
            </header>
        </div>
    </div>
)

export default App
